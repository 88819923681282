import { create } from "apisauce";

const token = localStorage.getItem("token");

// baseURL: 'https://energycommissionbackend.herokuapp.com/',
// localhost: "http://localhost:9006/api",
// "https://www.energycom.ghwebs.com/api"
const api = create({
  baseURL: "https://energycom.ghwebs.com/api",
  headers: { Authorization: `Bearer ${token}` },
});

export default api;
